<template>
    <section>
        <modal-lateral ref="modalCupon">
            <template slot="title-edit">
                <div class="row mx-0 align-items-center">
                    <div class="col-auto px-0">
                        <div class="text-general bg-white py-1 px-2 br-8 cr-pointer" @click="noUsarCupon">
                            No usar
                        </div>
                    </div>
                    <div class="col text-center px-0">
                        <p>
                            Agregar Cupón
                        </p>
                    </div>
                </div>
            </template>
            <div class="row d-middle mx-2">
                <div class="col-12">
                    <el-input v-model="codigo_cupon" placeholder="Ingresar cupón" class="w-100 input-validar-cupon">
                        <template slot="append">
                            <div class="bg-general text-white br-20 f-14 px-2 py-1 cr-pointer" @click="buscarCupon">
                                Validar
                            </div>
                        </template>
                    </el-input>
                </div>
            </div>
            <div v-for="(c,index) in cupones" :key="index" class="row align-items-center m-3  cr-pointer" :class="{'disabled-div':!c.aplica}">
                <div v-if="cupon_activo" class="col-auto px-2">
                    <i v-if="cupon_activo == c.id" class="icon-ok-circled text-general f-22" />
                    <div v-else class="rounded-circle border" style="width:27px;height:27px;" />
                </div>
                <div class="col d-flex pl-0 border br-10" @click.stop="seleccionar_cupon(c.id, c)">
                    <div class="bg-general d-middle-center br-l-10" style="width:93px;height:93px;">
                        <i :class="`icon-${ c.descuento_tipo == 1 ? 'cupon-porcentaje' : 'coin'} f-30 text-white`" />
                    </div>
                    <div class="col">
                        <p class="text-general f-22 f-500">
                            <span> {{ c.descuento_tipo == 2 ? '$' : '' }}</span>
                            {{ separadorNumero(c.descuento_valor, 0, false) }} 
                            <span> {{ c.descuento_tipo == 1 ? '%' : '' }}</span>
                        </p>
                        <p class="f-14 text-general"> <span class="mr-1">Compra mínima:</span> {{ separadorNumero(c.compra_minima) }} </p>
                        <p class="f-14 text-general tres-puntos">Expira: {{ formatearFecha(c.expira, 'DD MMMM Y') }} </p>
                    </div>
                </div>
            </div>
        </modal-lateral>
        <!-- Partials -->
        <modal-detalle-cupon ref="modalDetalleCupon" :detalle-cupon="detalle_cupon" @close="otro()" @update="$emit('update')" />
    </section>
</template>

<script>
import PedidosNew from "~/services/pedidos/pedidos-tendero";
import { mapGetters } from "vuex";

export default {
    components: {
        modalDetalleCupon: () => import('./modalDetalleCupon')
    },
    data(){
        return {
            cupones: [],
            codigo_cupon:'',
            cupon_activo: null,
            test: 0,
            detalle_cupon: {}
        };
    },
    computed: {
        ...mapGetters({
            resumen: "pedidos/pedidos_tendero/resumen",
        }),
    },
    methods: {
        toggle(){
            this.listar_cupones();
            this.codigo_cupon = ''
            this.$refs.modalCupon.toggle();
        },
        async listar_cupones(){
            try {
                const { data } = await PedidosNew.get_cupones();
                console.log(data, 'esta es la data');
                this.cupones = data.cupones

            } catch (e){
                this.error_catch(e)
            }
        },
        async noUsarCupon(){
            this.cupon_activo = null;
            this.$refs.modalCupon.toggle();
            this.$emit('no-usar');
        },
        async seleccionar_cupon(id_cupon, dataCupon){
            try {

                const { data } = await PedidosNew.seleccionar_cupon(id_cupon);

                console.log(data, dataCupon);
                this.cupon_activo = id_cupon;

                /* this.$emit('update') */

                this.detalle_cupon = dataCupon;
                
                this.$refs.modalCupon.toggle();

                this.$refs.modalDetalleCupon.toggle();


            } catch (e){
                this.error_catch(e)
            }
        },
        otro(){
            this.$refs.modalDetalleCupon.toggle();
            this.$refs.modalCupon.toggle();
        },
        async buscarCupon(){
            try {
                if(this.codigo_cupon === '') return
                const { data } = await PedidosNew.buscar_cupon(this.codigo_cupon);
                console.log(data, 'dawdawd');
                if(data == undefined){
                    this.notificacion('Mensaje', 'texto obligatorio', 'warning');
                } 
                else {
                    this.detalle_cupon = data.cupon;
                    
                    this.$refs.modalCupon.toggle();
    
                    this.$refs.modalDetalleCupon.toggle();
                }

            } catch (e){
                this.error_catch(e)
            }
        }
    },
};
</script>